import { render, staticRenderFns } from "./wsxx.vue?vue&type=template&id=2ed20956&scoped=true&"
import script from "./wsxx.vue?vue&type=script&lang=js&"
export * from "./wsxx.vue?vue&type=script&lang=js&"
import style0 from "./wsxx.vue?vue&type=style&index=0&id=2ed20956&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed20956",
  null
  
)

export default component.exports